import { Navigate } from "react-router-dom";
import AuthService from "../services/auth.service";

const ProtectedRoute = ({ children }) => {
    const isUserLoggedIn = AuthService.checkIfUserIsLoggedIn();

    return isUserLoggedIn ? children : <Navigate to="/login?sessionExpired=true" />;
};

export default ProtectedRoute;
