import { useNavigate } from 'react-router-dom';

const useNavigateUserService = () => {
    const navigate = useNavigate();

    const handleGoLoginPage = () => {
        navigate(process.env.PUBLIC_URL + "/login");
    };

    const handleGoHomePage = () => {
        navigate(process.env.PUBLIC_URL + "/home");
    };

    const handleGoResetYourPassword = () => {
        navigate(process.env.PUBLIC_URL + "/forgot-password/request-reset-link");
    };

    const handleGoRequestNewRegistrationConfirmationEmailPage = (email) => {
        navigate(process.env.PUBLIC_URL + `/register/request-new-registration-confirmation-email?email=${email}`);
    };

    return {
        handleGoLoginPage,
        handleGoHomePage,
        handleGoResetYourPassword,
        handleGoRequestNewRegistrationConfirmationEmailPage
    };


}


export default useNavigateUserService;