import {API_BASE_URL} from "../constants";

const UserRegistrationService = {


    confirmNewUserRegistration: async (paramsToken, paramsEmail) => {
        const confirmRegistrationUrl = `${API_BASE_URL}/api/user/register/confirm-registration`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                email: paramsEmail,
                token: paramsToken
            }),
        };
        const response = await fetch(confirmRegistrationUrl, requestOptions);
        return await response.json()
    },


    resendConfirmationRegistrationEmail: async (paramsToken, email) => {
        const resendConfirmationRegistrationEmailUrl = `${API_BASE_URL}/api/user/register/resend-confirmation-registration`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                email: email,
                token: paramsToken
            }),
        };
        const response = await fetch(resendConfirmationRegistrationEmailUrl, requestOptions);
        return await response.json()
    },

    requestNewConfirmationRegistrationEmail: async (email) => {
        const requestNewConfirmationRegistrationEmailUrl = `${API_BASE_URL}/api/user/register/request-new-confirmation-registration`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify({
                email: email
            }),
        };
        const response = await fetch(requestNewConfirmationRegistrationEmailUrl, requestOptions);
        return await response.json()
    },

    responseDictionary(message, email) {
        let responseMessage = '';

        if (message === "1") {
            responseMessage =
                <>
                    <h4>User email is already confirmed!</h4>
                    <h4>Registration completed. Account is active.</h4>
                </>;
        }
        if (message === "2") {
            responseMessage =
                <>
                    <h4>User email confirmed!</h4>
                    <h4>Registration completed. Account is active</h4>
                </>;
        }
        if (message === "3") {
            responseMessage =
                <>
                    <h4>Invalid or missing registration token!</h4>
                    <h4>Account is not active. Please request a new confirm registration email.</h4>
                </>;
        }
        if (message === "4") {
            responseMessage =
                <>
                    <h4>Invalid user email provided with the confirm registration token!</h4>
                    <h4>Please request a new confirm registration email.</h4>
                </>;
        }
        if (message === "5") {
            responseMessage =
                <>
                    <h4>The User with the provided email ( {email} ) doesn't exist!</h4>
                    <h4>Please check again provided email or create a new account on Numigram.</h4>
                </>;
        }
        if (message === "6") {
            responseMessage =
                <>
                    <h4>New user registration.</h4>
                    <h4>Something went wrong while sending an email to the user.</h4>
                    <h4>Address: {email}</h4>
                </>;
        }
        if (message === "7") {
            responseMessage =
                <>
                    <h4>New user registration.</h4>
                    <h4>Confirmation email sent to the address: {email}</h4>
                    <h4>To activate your account please confirm your email in 24 hours.</h4>
                </>;
        }
        if (message === "8") {
            responseMessage =
                <>
                    <h4>Registration token expired!</h4>
                    <h4>Please request a new confirm registration email.</h4>
                </>;
        }
        if (message === "9") {
            responseMessage =
                <>
                    <h4>Wrong user email: {email} or provided token.</h4>
                    <h4>Did you use the correct confirm registration link?</h4>
                </>;
        }
        if (message === "10") {
            responseMessage =
                <>
                    <h4>Authentication failed for the User: {email}</h4>
                    <h4>User account is NOT ENABLED.</h4>
                    <h4>To enable your account please confirm your email provided during the registration.</h4>
                    <h4>If you lost your account activation link, please request a new one:</h4>
                </>;
        }
        if (message === "11") {
            responseMessage =
                <>
                    <h4>Unfortunately, something went wrong.</h4>
                    <h4>Failed to log in user. Please try again later.</h4>
                    <h4>If the error persists, contact our support: contact@numigram.com</h4>
                </>;
        }
        if (message === "12") {
            responseMessage =
                <>Username already exists!</>;
        }
        if (message === "13") {
            responseMessage =
                <>Email already exists!</>;
        }
        if (message === "14") {
            responseMessage =
                <>User not found!</>;
        }

        return responseMessage;
    },

}

export default UserRegistrationService;