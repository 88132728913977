import PropTypes from "prop-types";
import clsx from "clsx";
import SubscribeEmail from "./sub-components/SubscribeEmail";

const FooterNewsletter = ({
  spaceBottomClass,
  spaceLeftClass,
  colorClass,
  widgetColorClass
}) => {
  return (
      <div className={clsx("footer-widget", spaceBottomClass, spaceLeftClass, widgetColorClass)}>

          <div className="row">
              <div className="col-xl-12 col-sm-12">
                  <div className="footer-title text-left">
                      <h3><i className="fa fa-envelope"> </i> NEWSLETTER SIGN UP</h3>
                  </div>
                  <div className={clsx("subscribe-style", colorClass)}>
                      <p>Get latest news and updates</p>
                      <SubscribeEmail mailchimpUrl="https://numigram.us9.list-manage.com/subscribe/post?u=2393d21b1e11ea7b26451bb85&amp;id=73139a90f3&amp;f_id=00d31ce0f0"/>
                  </div>
              </div>
              </div>
              </div>
              );
              };

              FooterNewsletter.propTypes = {
              spaceBottomClass: PropTypes.string,
              spaceLeftClass: PropTypes.string,
              colorClass: PropTypes.string,
              widgetColorClass: PropTypes.string
          };

              export default FooterNewsletter;
