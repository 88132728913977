import React, {lazy, Suspense, useEffect} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Login from "./pages/LoginRegisterPage";
import ScrollToTop from "./helpers/scroll-top";
import ProtectedRoute from "./components/ProtectedRoute";
import ErrorBoundary from "./components/ErrorBoundary";

const HomePage = lazy(() => import("./pages/home/HomePage"));
const MyCoinsCollection = lazy(() => import("./pages/coins/my/MyCoinsPage"));
const CoinEdit = lazy(() => import("./pages/coins/edit/MyCoinsEditPage"));
const CoinStickyPage = lazy(() => import("./pages/coins/details/CoinStickyPage"));
const MedalEdit = lazy(() => import("./pages/medals/edit/MyMedalsEditPage"));
const MedalStickyPage = lazy(() => import("./pages/medals/details/MedalStickyPage"));
const MyMedalsCollection = lazy(() => import("./pages/medals/my/MyMedalsPage"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const ChangePasswordPage = lazy(() => import("./pages/other/ChangePasswordPage"));
const ChangeForgottenPasswordPage = lazy(() => import("./pages/other/ChangeForgottenPasswordPage"));
const RequestResetPasswordPage = lazy(() => import("./pages/other/RequestResetPasswordPage"));
const MyCoinsCollections = lazy(() => import("./pages/other/MyCoinsCollections"));
const MyMedalsCollections = lazy(() => import("./pages/other/MyMedalsCollections"));
const NotFound = lazy(() => import("./pages/other/NotFound"));
const ContactUs = lazy(() => import("./pages/other/Contact"));
const AboutUs = lazy(() => import("./pages/other/About"));
const NumigramBlog = lazy(() => import("./pages/blog/NumigramBlog"));
const NumigramBlogPressRelease = lazy(() => import("./pages/blog/BlogPressRelease"));
const ResourceNotFound = lazy(() => import("./pages/other/ResourceNotFound"));
const ComingSoon = lazy(() => import("./pages/other/ComingSoon"));
const ConfirmRegistrationPage = lazy(() => import("./pages/other/ConfirmRegistrationPage"));
const RequestNewRegistrationConfirmationEmailPage = lazy(() => import("./pages/other/RequestNewRegistrationConfirmationEmailPage"));

const App = () => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://www.googletagmanager.com/gtag/js?id=G-Y7DP44M05J";
        script.async = true;
        document.head.appendChild(script);

        window.dataLayer = window.dataLayer || [];

        function gtag() {
            window.dataLayer.push(arguments);
        }

        gtag('js', new Date());
        gtag('config', 'G-Y7DP44M05J');
    }, []);

    return (
        <Router>
            <ErrorBoundary>
                <ScrollToTop>
                    <Suspense fallback={
                        <div className="tlp-preloader-wrapper">
                            <div className="tlp-preloader">
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    }
                    >
                        <Routes>
                            {/*Public Routes*/}
                            <Route path={process.env.PUBLIC_URL + "/"} element={<HomePage/>}/>
                            <Route path={process.env.PUBLIC_URL + "/home"} element={<HomePage/>}/>
                            <Route path={process.env.PUBLIC_URL + "/contact-us"} element={<ContactUs/>}/>
                            <Route path={process.env.PUBLIC_URL + "/about-us"} element={<AboutUs/>}/>

                            <Route path={process.env.PUBLIC_URL + "/numigram-blog"} element={<NumigramBlog/>}/>
                            <Route path={process.env.PUBLIC_URL + "/blog-numigram-press-release"} element={<NumigramBlogPressRelease/>}/>

                            <Route path="*" element={<NotFound/>}/>
                            <Route path={process.env.PUBLIC_URL + "/resource-not-found"} element={<ResourceNotFound/>}/>
                            <Route path={process.env.PUBLIC_URL + "/coming-soon"} element={<ComingSoon/>}/>

                            <Route path={process.env.PUBLIC_URL + '/login'} exact={true} element={<Login/>}/>
                            <Route path={process.env.PUBLIC_URL + '/register'} exact={true} element={<Login/>}/>
                            <Route path={process.env.PUBLIC_URL + '/register/confirm-registration'} element={<ConfirmRegistrationPage/>}/>
                            <Route path={process.env.PUBLIC_URL + '/register/request-new-registration-confirmation-email'} element={<RequestNewRegistrationConfirmationEmailPage/>}/>

                            <Route path={process.env.PUBLIC_URL + "/forgot-password/request-reset-link"} element={<RequestResetPasswordPage/>}/>
                            <Route path={process.env.PUBLIC_URL + "/forgot-password/change"} element={<ChangeForgottenPasswordPage/>}/>

                            {/* Protected Route */}
                            <Route path={process.env.PUBLIC_URL + "/my-coins-collections/all"} element={<ProtectedRoute><MyCoinsCollection/></ProtectedRoute>}/>
                            <Route path={process.env.PUBLIC_URL + "/my-coins-collections/user-coin/:userCoinId"} exact={true} element={<ProtectedRoute><CoinEdit/></ProtectedRoute>}/>
                            <Route path={process.env.PUBLIC_URL + "/my-coins-collections/user-coin/view/:id"} element={<ProtectedRoute><CoinStickyPage/></ProtectedRoute>}/>

                            <Route path={process.env.PUBLIC_URL + "/my-medals-collections/all"} element={<ProtectedRoute><MyMedalsCollection/></ProtectedRoute>}/>
                            <Route path={process.env.PUBLIC_URL + "/my-medals-collections/user-medal/:userMedalId"} exact={true} element={<ProtectedRoute><MedalEdit/></ProtectedRoute>}/>
                            <Route path={process.env.PUBLIC_URL + "/my-medals-collections/user-medal/view/:id"} element={<ProtectedRoute><MedalStickyPage/></ProtectedRoute>}/>

                            <Route path={process.env.PUBLIC_URL + "/my-account"} element={<ProtectedRoute><MyAccount/></ProtectedRoute>}/>
                            <Route path={process.env.PUBLIC_URL + "/my-coins-collections"} element={<ProtectedRoute><MyCoinsCollections/></ProtectedRoute>}/>
                            <Route path={process.env.PUBLIC_URL + "/my-coins-collections/collection/:coinsCollectionId"} element={<ProtectedRoute><MyCoinsCollections/></ProtectedRoute>}/>
                            <Route path={process.env.PUBLIC_URL + "/my-medals-collections"} element={<ProtectedRoute><MyMedalsCollections/></ProtectedRoute>}/>
                            <Route path={process.env.PUBLIC_URL + "/my-medals-collections/collection/:medalsCollectionId"} element={<ProtectedRoute><MyMedalsCollections/></ProtectedRoute>}/>

                            <Route path={process.env.PUBLIC_URL + "/change-user-password"} element={<ProtectedRoute><ChangePasswordPage/></ProtectedRoute>}/>

                        </Routes>
                    </Suspense>
                </ScrollToTop>
            </ErrorBoundary>
        </Router>
    )
}

export default App;