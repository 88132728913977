const MobileWidgets = () => {
    return (
        <div className="offcanvas-widget-area">
            <div className="off-canvas-contact-widget">
                <div className="header-contact-info">
                    <ul className="header-contact-info__list">
                        <li>
                            <i className="fa fa-envelope"></i>{" "}
                            <a href="mailto:contact@numigram.com">contact@numigram.com</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="off-canvas-widget-social">
                {/*<a href="//twitter.com" title="Twitter">*/}
                {/*    <i className="fa fa-twitter"></i>*/}
                {/*</a>*/}
                <a href="https://www.instagram.com/numigram/" title="Numigram Instagram">
                    <i className="fa fa-instagram"></i>
                </a>
                <a href="https://www.facebook.com/Numigram" title="Numigram Facebook">
                    <i className="fa fa-facebook"></i>
                </a>
            </div>
        </div>
    );
};

export default MobileWidgets;
