import PropTypes from "prop-types";
import clsx from "clsx";
import {Link} from "react-router-dom";
import FooterCopyright from "../../components/footer/FooterCopyright";
import FooterNewsletter from "../../components/footer/FooterNewsletter";


const Footer = ({
                    footerTopBackgroundColorClass,
                    footerTopSpaceTopClass,
                    footerTopSpaceBottomClass,
                    spaceLeftClass,
                    spaceRightClass,
                    backgroundColorClass,
                }) => {

    return (
        <footer className={clsx("footer-area", backgroundColorClass, spaceLeftClass, spaceRightClass)}>
            <div className={clsx("footer-top", footerTopBackgroundColorClass, footerTopSpaceTopClass, footerTopSpaceBottomClass)}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-3 col-sm-6">
                            <div className="row">
                                <div className="col-xl-12 col-sm-12">
                                    <FooterCopyright footerLogo="/assets/img/logo_numigram.png" spaceBottomClass="mb-10"/>
                                </div>
                                <div className="col-xl-12 col-sm-12">
                                    <div className={clsx("copyright", "mb-30",  "ml-10")}>
                                        <p className="text-left">
                                            &copy; {new Date().getFullYear()}{" "}
                                            <a href="https://numigram.com" rel="noopener noreferrer" target="_blank">NUMIGRAM.com</a><br/> - All Rights Reserved
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-sm-12">
                            <div className="row">
                                <div className="col-xl-4 col-sm-12">
                                    <div className="footer-widget mb-30 ml-20">
                                        <div className="footer-title">
                                            <h3>SUPPORT</h3>
                                        </div>
                                        <div className="footer-list">
                                            <ul>
                                                <li>
                                                    <Link to={process.env.PUBLIC_URL + "/about-us"}>
                                                        FAQ
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={process.env.PUBLIC_URL + "/about-us"}>
                                                        About Us
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={process.env.PUBLIC_URL + "#/"}>
                                                        Terms and Conditions
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-sm-12">
                                    <div className="footer-widget mb-30 ml-20">
                                        <div className="footer-title">
                                            <h3>CONTACT</h3>
                                        </div>
                                        <div className="footer-list">
                                            <ul>
                                                <li>
                                                    <Link to={process.env.PUBLIC_URL + "/contact-us"}>
                                                        Contact Us
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={process.env.PUBLIC_URL + "/contact-us"}>
                                                        Send Feedback
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-sm-12">
                                    <div className="footer-widget mb-30 ml-20">
                                        <div className="footer-title">
                                            <h3>PAGES</h3>
                                        </div>
                                        <div className="footer-list">
                                            <ul>
                                                <li>
                                                    <Link to={process.env.PUBLIC_URL + "/numigram-blog"}>
                                                        Blog & Articles
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={process.env.PUBLIC_URL + "/my-coins-collections/all"}>
                                                        My Coins Collections
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={process.env.PUBLIC_URL + "/my-medals-collections/all"}>
                                                        My Medals Collections
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-3 col-sm-4">
                            <FooterNewsletter
                                spaceBottomClass="mb-30"
                                spaceLeftClass="ml-20"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

Footer.propTypes = {
    backgroundColorClass: PropTypes.string,
    containerClass: PropTypes.string,
    extraFooterClass: PropTypes.string,
    spaceBottomClass: PropTypes.string,
    spaceTopClass: PropTypes.string,
    spaceLeftClass: PropTypes.string,
    spaceRightClass: PropTypes.string
};

export default Footer;
