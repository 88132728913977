import {API_BASE_URL} from "../constants";

const PasswordValidationService = {

    validatePasswordForm (password, repeatPassword)  {
        let error = '';

        if (password.length < 10) {
            error = 'Password must be at least 10 characters long.';
        } else if (!/[A-Z]/.test(password)) {
            error = 'Password must contain at least one uppercase letter.';
        } else if (!/[a-z]/.test(password)) {
            error = 'Password must contain at least one lowercase letter.';
        } else if (!/\d/.test(password)) {
            error = 'Password must contain at least one number.';
        } else if (password !== repeatPassword) {
            error = 'Passwords do not match.';
        }

        return {
            isValid: error === '',
            error: error
        };
    },

    validatePasswordResetToken: async (token) => {
        const validatePasswordResetTokenUrl = `${API_BASE_URL}/api/user/pwd/change-user-password/validate-token`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: token,
        };
        const response = await fetch( validatePasswordResetTokenUrl, requestOptions);
        if (response.ok) {
            return await response.text()
        } else {
            return "expired"
        }
    }

}

export default PasswordValidationService;